import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonVariant } from '@naf/button';
import { MEMBERPAGES_URL } from '../../../lib/constants';
import { useTrackCTA } from '../../../utils/Tracking/CtaClick/TrackCtaClick';

interface Props {
  buttonText?: string;
  returnUrl?: string;
  variant?: ButtonVariant;
}

export const LoginButton = ({ buttonText, returnUrl, variant }: Props) => {
  const { loginWithRedirect } = useAuth0();
  const returnToUrl = returnUrl || `/${MEMBERPAGES_URL}`;
  const trackCTA = useTrackCTA();

  return (
    <Button
      onClick={() => {
        trackCTA({ cta_position: 'content', text: buttonText || 'Logg inn' });
        loginWithRedirect({ appState: { returnTo: returnToUrl } });
      }}
      variant={variant}
    >
      {buttonText || 'Logg inn'}
    </Button>
  );
};
